<template>
    <div>训练管理员装修评分</div>
</template>

<script>
    export default {
        name: "DecorationScore"
    }
</script>

<style scoped>

</style>